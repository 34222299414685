@import "payroll";
@import "tax";
@import "pension";
@import "reprocess";
@import "retailVendor";
@import "bulkPayment";
@import "bill";
@import "vatPayment";



.paymentsPage {
  padding: 50px;

  .paymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    padding-block: 23px 50px;
  }

  .paymentRadioButtons {
    background: #FFFFFF;
    width: 480px;
    display: grid;
    margin: auto;
    padding: 40px;

    label {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding-bottom: 10px;
      //padding-left: 5px;
    }

    .ppProceedButton {
      padding-top: 30px;

      button {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        background: #666666;
        padding: 14px 70px;
        border: none;
        width: 100%;
      }
    }
  }
}


