//
// Text
//

// Text colors
@each $name,
$color in $theme-notification-background-colors {

  // Base color
  .notification-#{$name} {
    background-color: $color !important;
    width: 382px;
    height: auto;
    border: 1px solid $color;
    border-radius: 5px;
    padding: 20px 0 12px 18px;
    box-sizing: border-box;
    display: flex;

  }

  .notification-text-box{
    display: flex;
    flex-direction: row;

  }
  .notification-img-container{
    margin: 5px;
    
    
  }
  .notification-text-box2{
    display: flex;
    flex-direction: column;
  }

  .notification-success-text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #131212;
  }
  .notification-success-body-text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #131212;
    word-wrap: normal;
  }


}