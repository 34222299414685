.expReceiptView {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .expReceiptViewRow1 {
    display: flex;
    justify-content: space-between;
    padding-block: 30px 26px;

    .expReceiptViewBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #061B01;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .expReceiptViewRow2 {
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
    padding-block: 34px 31px;
    max-width: 882px;

    > :first-child {
      padding-inline: 44px;
      border-bottom: 1px solid #ECECEC;

      > :first-child {
        color: #666666;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      > :nth-child(2) {
        color: #4F4F4F;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-block: 5px 23px;
      }
    }

    > :last-child {
      padding-inline: 29px 37px;
      padding-top: 29px;
    }
  }

  .expReceiptViewRow3 {
    display: flex;
    gap: 22px;
    padding-block: 25px 60px;
    max-width: 882px;

    > :first-child {
      border-radius: 6px;
      background: #FFFFFF;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
      flex-basis: 65%;
      padding-inline: 47px;
      padding-block: 20px;
    }

    > :last-child {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex-basis: 35%;

      > div {
        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
        padding: 12px 16px;

        > :first-child {
          color: #828282;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > :nth-child(2) {
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1.5px;
        }

        > :nth-child(3) {
          color: #4F4F4F;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }

  .transHistory {
    color: #000000;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 18px;
  }

  .expReceiptViewTab {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 16px 32px;
    border-radius: 5px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

    .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: #666666 !important;
    }
  }

  .expReceiptViewRow4 {
    > :nth-child(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .expReceiptViewSelectOptions {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        padding-block: 12px 28px;

        .ant-select-single .ant-select-selector {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #000000;
        }

        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: rgba(255, 255, 255, 0.80);
          background: #666666;
          border-radius: 4px;
          padding: 8px;
          gap: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
        }
      }

      > :nth-child(2) {
        display: flex;
        gap: 3px;
        justify-content: right;

        > :first-child {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        > :nth-child(2) {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
}
