//
// Text
//

// Text colors
@each $name,
$color in $theme-text-colors {
  // Base color


  .login-root {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    height: 100vh;
    width: 100%;
    background: #F5F5F5;
  }

  // .login-root> :nth-child(2) {
  //   /* Set the order to -1 to move it to the left */
  //   order: -1;
  // }

  .login-aside {
    display: none;
    // background: #D2E7CD;
  }

  .login-aside-img {
    display: grid;
    justify-content: center;
    height: 70%;
  }

  .img-login {
    display: grid;
    justify-content: end;
    place-items: end;
  }

  .login-aside-footer {
    display: grid;
    height: 30%;
    justify-content: center;

  }

  .login-aside-footer {
    background: #061B01;
  }

  .login-aside-footer div {
    padding: 3rem;
  }

  .login-aside-footer span {
    font-family: 'Poppins' sans-serif;
    ;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;

    color: #FFFFFF;
  }

  .login-main {
    // display: grid;
    // place-content: center;
    background: #f5f5f5;
  }

  .img-center {
    display: block;
    text-align: center;
    direction: rtl;
    padding: 1rem 1rem;
  }

  .login-text {
    display: block;
    text-align: center;
    direction: rtl;
  }

  .login_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    /* identical to box height */


    color: hsl(140, 43%, 5%);
  }

  .login-remeber-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* identical to box height */


    color: #08140C;
  }

  .login-rem-grid{
    display: grid;
    float: right;
  }

  .forgot-password-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* identical to box height */



    color: #666666;
  }

  .login-forgot-password-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #1F8505;
    float: right
  }

  login-text-header {
    display: block;
    text-align: center;
    direction: rtl;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    /* identical to box height */


    color: #08140C;
  }

  // .login-center{
  //   pla
  // }

  // @media screen and (min-width: 580px){
  //   .login-root {
  //     display: grid;
  //     grid-template-columns: 1fr 2fr;
  //     grid-auto-flow: dense;

  //     height: 100vh;
  //     width: 100%;
  //     background: #F5F5F5;

  //   }

  //   .login-root > :nth-child(2) {
  //     /* Set the order to -1 to move it to the left */
  //     order: 1;
  //   }
  // }

  .modal-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
text-align: center;

color: #666666;
margin-bottom: 1rem;
  }


  @media screen and (min-width: 992px) {
    .login-root {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-auto-flow: dense;

      height: 100vh;
      width: 100%;
      background: #F5F5F5;

    }
    .login-aside {
      display: block;
      background: #D2E7CD;
    }
    // .login-root> :nth-child(2) {
    //   /* Set the order to -1 to move it to the left */
    //   order: 1;
    // }

    .login-remeber-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      /* identical to box height */
      color: #08140C;
    }

    .login-rem-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .login-rem-grid-signup{
      display: grid;
      grid-template-columns: 1fr ;
    }

   
  }


}