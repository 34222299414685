@use 'core/assets/sass/components/variables' as *;


.create-content-container {
    background-color: $white;
    padding: 50px 47px;
    display: grid;
    margin: auto;
    width: 881px;

    .header-container {
        display: flex;
        padding: 20px 20px 16px 20px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 0.5px solid $unchecked-switch;
        background: $white;
    }

    :where(.css-dev-only-do-not-override-1drr2mu).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid $gray-800;
        background: $gray-800;
    }

    .create-content {
        width: 480px;
        margin: auto;
        padding: 50px;
        border-radius: 5px;
        background: $white;
        box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

}

.user-workflow-btn {
    width: 480px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
}

.table th {
    background-color: $light-success !important;
    color: $dark2 !important;
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    text-align: left !important;
}
.table tbody tr{
    background-color: #F2F2F2;
    text-align: left !important;
}
.table td {
    text-align: left !important;
}

.preview-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 20px;

    .preview-header {
        border-bottom: 1px solid $form-border;
        background: $white;
        padding: 20px 0;
        margin-bottom: 30px;
    }
}

.card {
    border-radius: 5px !important;
    background: #FFF !important;
    border-color: $white !important;
}

.project-preview-card {
    width: 944px !important;
    padding-top: 30px;
    padding-bottom: 50px;
}

.preview-btn-container {
    display: flex;
    justify-content: end;
    margin: 10px auto 20px auto ;
    width: 881px;

}