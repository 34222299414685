.collection-search {
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
  width: 400px;
  height: 2.563rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 885px) {
    width: 100%;
  }
}

.dash {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 10px;
  gap: 32px;

  width: 542px;
  height: 50px;

  background: #eef9eb;
  border: 2px dashed #a5ce9b;
  border-radius: 5px;
}

.removeFields {
  width: 546px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 885px) {
    width: 100%;
  }
}

.collection-card {
  padding: 5rem;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-top: 2.5rem;

  @media screen and (max-width: 885px) {
    padding: 2.5rem;
  }
}

.inputItems {
  background: #d2e7cd;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #a5ce9b;
  border-radius: 5px 0px 0px 5px;
  min-width: 207px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadsItems {
  min-width: 207px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 0px 5px 5px 0px;
}

.grid-3C {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
