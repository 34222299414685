.payment-upload-summary {

  color: #08140C;

  border-radius: 4px;
  background: #FFF;

  padding: 0 20px;

  .pus-Row1 {
    border-radius: 4px;
    padding: 20px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      padding-block: 18px 16px;
      border-bottom: 1px solid #E8E8E8;
    }

    > :nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-block: 16px;
      border-bottom: 1px solid #E8E8E8;
      grid-template-columns: 1fr 1fr;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #1F8505;
        padding-bottom: 7px;
        display: flex;
        justify-content: space-between;
        gap: 75px
      }

      > :nth-child(2) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
      }
    }

    > :last-child {
      display: flex;
      justify-content: space-between;
      padding-block: 15px;

      > :first-child {
        display: flex;
        justify-content: space-between;
        gap: 37px;
      }

      > :last-child {
        display: flex;
        justify-content: space-between;
        gap: 60px;
      }

    }
  }

}

.left-row {
  > :first-child {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #666666;
  }

  > :nth-child(2) {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #061B01;
  }
}

.right-row {
  display: grid;
  gap: 7px;

  > :first-child {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #1F8505;
  }

  > :nth-child(2) {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 4px;
    color: #666;
  }
}