.roleResourceManagement {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .roleResourceManagementBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 30px 48px;

    > :first-child {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > :last-child {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .roleResourceManagementDiv {
    border-bottom: 1px solid #CCCCCC;
    background: #FFF;
    padding: 50px;

    .rrmRow2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 48px;

      > :first-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      .createNewRoleButton {
        button {
          color: #FFF;
          text-align: center;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          background: var(--Primary-Color, #1F8505);
          padding: 12px 20px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  .editRoleView {
    .editRoleViewBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-block: 30px 48px;

      > :first-child {
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      > :nth-child(3) {
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      > :last-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .editRoleForm {
      border-radius: 5px;
      background: #FFF;
      box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08);
      padding: 30px 150px;
      max-width: 700px;
      margin: auto;

      .editRoleTextDiv {
        display: flex;

        > :first-child {
          flex-basis: 5%;
          cursor: pointer;
        }

        .editRoleText {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: center;
          padding-bottom: 36px;
          flex-basis: 95%;
        }
      }

      .roleName {
        padding-bottom: 26px;

        label {
          color: #666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .resourceText {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .selectResourceText {
        color: #666;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .allResourceDiv {
        border: 1px solid #E2E2E2;
        margin-block: 14px 36px;

        .allResourcesText {
          color: var(--Primary-Color, #1F8505);
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background: #D1F1C9;
          padding: 10px;
          text-align: center;
        }

        .editRoleCheckbox {
          padding: 25px 33px;
          height: calc(100vh - 445px);
          overflow-y: auto;
          display: grid;

          label {
            color: #08140C;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          //.checkbox-dropdown {
          //  display: grid;
          //  gap: 13px;
          //  padding-left: 30px;
          //
          //  label {
          //    display: flex;
          //    align-content: center;
          //    color: #08140C;
          //    font-family: Poppins, sans-serif;
          //    font-size: 12px;
          //    font-style: normal;
          //    font-weight: 400;
          //    line-height: normal;
          //    padding-bottom: 10px;
          //  }
          //
          //  input {
          //    margin-right: 15px;
          //  }
          //}
        }
      }

      .editRolePreviewButton {
        //margin: auto;
        text-align: center;

        button {
          color: #FFF;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 5px;
          background: #1F8505;
          padding: 12px 34px;
          border: none;
        }
      }
    }
  }
}

.edit-button {
  border-radius: 50px;
  background: var(--Light-Green, #D2E7CD);
  color: var(--Primary-Color, #1F8505);
  font-family: Poppins, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 3px 34px;
  border: none;
}
