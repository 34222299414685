.OagfOverviewPage {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .opRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }

  .opRow2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 11px 25px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      display: flex;
      gap: 9px;
      justify-content: center;
      align-items: center;
    }

    > :last-child {
      display: flex;
      gap: 25px;
      justify-content: center;
      align-items: baseline;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #061B01;
      }
    }
  }

  .opRow3 {
    display: flex;
    justify-content: right;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 13px 19px;
    gap: 30px;

    .opRow3Card {
      width: 200px;
      //height: 114px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 16px 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }

      > :nth-child(2) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #061B01;
        padding-block: 3px 5px;
      }

      > :last-child {
        button {
          background: #FBCB38;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
          border-radius: 2px;
          padding: 2px 8px;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #08140C;
          border: none;
        }
      }
    }
  }

  .opRow4 {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 50px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      padding-block: 15px 21px;
    }

    > :last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;

      .opRow4CardDiv {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 9px;

        .opRow4CardDiv1 {
          width: 260px;
          height: 153.4px;
          background: #EDF9F1;
          border: 1px solid #A5CE9B;
          border-radius: 4px;
          padding: 23px 16px 15px 26px;

          > :first-child {
            display: flex;
            justify-content: space-between;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #666666;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
            padding-top: 12px;
          }

          > :nth-child(3) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #102818;
            padding-block: 2px 7px;
          }

          > :last-child {
            display: grid;
            justify-content: right;

            button {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #1F8505;
              padding: 3px 8px;
              background: #EDF9F1;
              border: 2px solid #1F8505;
              border-radius: 4px;
            }
          }
        }

        .opRow4CardDiv2 {
          width: 260px;
          height: 153.4px;
          background: #FEF5D7;
          border: 1px solid #FBCB38;
          border-radius: 4px;
          padding: 23px 16px 15px 26px;

          > :first-child {
            display: flex;
            justify-content: space-between;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #666666;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
            padding-top: 12px;
          }

          > :nth-child(3) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #102818;
            padding-block: 2px 7px;
          }

          > :last-child {
            display: grid;
            justify-content: right;

            button {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #1F8505;
              padding: 3px 8px;
              background: #EDF9F1;
              border: 2px solid #1F8505;
              border-radius: 4px;
            }
          }
        }

        .opRow4CardDiv3 {
          width: 260px;
          height: 153.4px;
          background: #FDE7E8;
          border: 1px solid #EE151D;
          border-radius: 4px;
          padding: 23px 16px 15px 26px;

          > :first-child {
            display: flex;
            justify-content: space-between;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #666666;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
            padding-top: 12px;
          }

          > :last-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #102818;
            padding-block: 2px 7px;
          }

        }

        .opRow4CardDiv4 {
          width: 260px;
          height: 153.4px;
          background: #E7F7FB;
          border: 1px solid #26BEDF;
          border-radius: 4px;
          padding: 23px 16px 15px 26px;

          > :first-child {
            display: flex;
            justify-content: space-between;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #666666;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
            padding-top: 12px;
          }

          > :last-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #102818;
            padding-block: 2px 7px;
          }
        }
      }

      .opRow4ActivityDiv {
        background: #FFFFFF;
        width: 495px;
        height: 300px;
        padding-bottom: 20px;
        // overflow-y: auto;


        > :first-child {

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          background: #FFFFFF;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
          padding: 10px 17px;
        }

        .opRow4Activity {
          display: flex;
          border-bottom: 1px solid #E8E8E8;
          padding-block: 30px 11px;
          padding-inline: 10px 20px;
          gap: 26px;

          >:nth-child(2) {
            display: grid;

            .opRow4ActivityLog {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #666666;
            }

            .opRow4ActivityTime {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #AAAAAA;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .capitalizeFirstTwoLetters{
    display: grid;
    padding: 0.5rem;
    background-color: #D1F1C9;
    border-radius: 50px;
  }
 
  .capitalizeText{
    color: #08140C;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  .opRow5 {
    >:first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      padding-block: 14px 22px;
    }

    >:last-child {
      display: flex;
      gap: 20px;

      .opRow5Card1 {
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 19px 35px 41px 28px;
        width: 399px;
        height: 400px;
        overflow: auto;

        >:first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #606060;
          display: flex;
          justify-content: space-between;
        }

          .opPerformingMda {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #606060;
            border-bottom: 1px solid #F4F4F4;
            padding-block: 8px 5px;
          }

      }

      .opRow5Card2 {
        display: grid;
        gap: 9px;

        .opRow5SectorCard {
          background: #FFFFFF;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          padding: 19px 31px;
          width: 398px;
          height: 200px;


          >:first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #606060;
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
          }

          .opPerformingSector {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #606060;
            border-bottom: 1px solid #F4F4F4;
            padding-block: 8px 5px;
          }
        }

        .opRow5RegionCard {
          background: #FFFFFF;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          padding: 19px 31px;
          width: 398px;
          height: 200px;
          overflow: auto;

          >:first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #606060;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}


     .opPerformingServices {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #606060;
            border-bottom: 1px solid #F4F4F4;
            padding-block: 8px 5px;
          }