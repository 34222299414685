

:where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f9f9f9;
    border-color: #e5e5e5;
    color: #061b01;
    border-radius: 0.25rem;
    border: 0;
    height: 2.313rem;
    display: flex;
    justify-content: center;
    align-items: center;
}