
.reviewerDashboard {
  padding: 35px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  // display: grid;
  gap: 48px;
  // width: 100vw;


  .ant-table-cell {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }

  .rdRow1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Poppins', serif;
    font-style: normal;
    font-size: 14px;
    line-height: 27px;
    color: #08140C;

    >:last-child {
      display: flex;
      align-items: center;
      gap: 40px;

      >div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .rdRow2 {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    .rdCard {
      display: grid;
      align-items: flex-start;
      padding: 30px 27px;
      gap: 10px;
      width: 330px;
      height: 126px;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      >:nth-child(1) {
        display: flex;
        justify-content: space-between;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #666666;
      }
      >:nth-child(2) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #102818;
      }
      >:nth-child(3) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }
  }

  .rdRow3 {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    .rdRow3Card {
      display: flex;
      align-items: center;
      padding: 12px 25px;
      box-sizing: border-box;
      gap: 32px;
      width: 330px;
      height: 99px;
      background: #FFFFFF;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);
      border-radius: 10px;

      >:last-child {
        display: grid;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        >:nth-child(1) {

          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
        }
        >:nth-child(2) {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #08140C
        }
        >:nth-child(3) {
          display: flex;
          gap: 5px;
          strong {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #08140C;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            color: #999999;
          }
        }
      }
    }
  }

  .rdRow4 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rdBigChart {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 30px;
      gap: 16px;
      width: 628.97px;
      height: 261px;
      background: #FFFFFF;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
    }

    .rdSmallCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 45px 24px;
      gap: 10px;

      width: 350px;
      height: 182px;

      background: #E5E5E5;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
    }
  }

  .ciBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    padding-block: 30px;
  }

  .ciRow1 {
    padding: 20px 20px 16px;
    // margin-inline: 50px;
    background: #FFFFFF;
    border-radius: 4px;

    .ministryOfEduText {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #08140C;
      text-align: center;
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: 10px;
    }

    >:last-child {
      display: flex;
      justify-content: space-between;
      padding-block: 15px;

      .ciTitle {
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        padding-bottom: 4px;
      }

      .ciValue {
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #061B01;
      }
    }
  }

  .reviewerTableContainer {
    // display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;
    gap: 48px;
    background: #FFFFFF;
    border-radius: 5px;
    // margin-inline: 50px;

    .reviewerTableDownload {
      display: flex;
      justify-content: end;
      gap: 17px;
      align-items: center;
    }

    .reviewerTableActionButton {
      display: flex;
      align-items: center;
      gap: 54px;

      .ciSearchButton {
        button {
          background: #D2E7CD;
          border: 1px solid #1F8505;
          border-radius: 5px;
          padding: 10px 15px;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }
      }

      .ciResetButton {
        button {
          background: #FEF5D7;
          border: 1px solid #FBCB38;
          border-radius: 5px;
          padding: 10px 15px;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }
      }
    }

    .ciSelectOptions {
      .ant-select-single .ant-select-selector {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        
      }

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        background: #FBCB38;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 8px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .rdActionButton {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 3px 10px;
      gap: 10px;
      width: 105px;
      height: 27px;
      background: #1f8505;
      border-radius: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #FFFFFF;
      border: none;
    }
  }

  .ant-select-show-search:where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
    border: 0;
}
}

.rdFilterModal {
  display: grid;
  gap: 40px;
  padding-inline: 30px;

  .rdFilterModalTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #08140C;
  }

  .rdFilterModalRow1 {
    display: grid;
    gap: 30px;
    >:nth-child(2) {
      display: grid;
      grid-template-columns: 49% 49%;
      align-items: center;
      gap: 2%;
    }

  }

  .rdFilterModalActionButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

