
@use 'core/assets/sass/components/variables' as *;

.project-touched-step {
    padding-left: 0.885rem;
    padding-right: 0.885rem;
    padding-top: 0.569rem;
    padding-bottom: 0.569rem;
    background: #1f8505;
    border: 0.25rem solid #fbcb38;
    color: $white;
    border-radius: 50%;
    min-width: 2.75rem;
  }
  
  .project-unTouched-step {
    padding-left: 0.885rem;
    padding-right: 0.885rem;
    padding-top: 0.569rem;
    padding-bottom: 0.569rem;
    background: #ffffff;
    border: 0.25rem solid #1f8505;
    color: $dark;
    border-radius: 50%;
    min-width: 2.75rem;
  }
  
  .overflow-none {
    overflow-x: unset !important;
  }
  
  .project-touched-line {
    width: 10rem;
    height: 0px;
    border: 0.125rem solid #1f8505;
    border-radius: 0.25rem;
  }
  
  .project-unTouched-line {
    width: 10rem;
    height: 0px;
    border-radius: 0.25rem;
    border: 0.125rem solid $white;
  }
  
  .overflow-y {
    overflow-y: scroll;
  }
  