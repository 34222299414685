.mdaDirectoryPage {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .mdaDirectoryRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .mdaDirectoryBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #08140C;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
    }
  }

 .mdaDirectoryRow2 {
   font-family: 'Poppins', sans-serif;
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 150%;
   color: #121212;
   padding-block: 90px 36px;
 }

  .mdaDirectoryRow3 {
    background: #FFFFFF;
    padding: 30px 50px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #061B01;
    }

    .mdaDirectorySelectOptions {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      padding-block: 12px 28px;

      .ant-select-single .ant-select-selector {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
      }

      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        background: #FBCB38;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 8px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
 }
}