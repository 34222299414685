.outstandingPayment {
  background: #F9F9F9;
  padding-inline: 30px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .outstandingPaymentRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;

    .outstandingPaymentBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :nth-child(3) {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        color: #061B01;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
    }
  }

  .outstandingPaymentRow2 {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 50px 47px;
    margin-top: 48px;

    > :first-child {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 20px;
    }

    > :nth-child(3) {
      display: flex;
      gap: 3px;
      justify-content: right;

      > :first-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      > :nth-child(2) {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    > :nth-child(4) {
      padding-block: 20px 38px;
      display: flex;
      gap: 20px;
      align-items: center;

      .filterButton {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #000000;
          background: #FBCB38;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          padding: 8px 9px;
          gap: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > :last-child {
        button {
          color: #000000;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 5px;
          border: 1px solid #CCC;
          background: #FEF5D7;
          padding: 8px 9px;
        }
      }
    }
  }
}
