.body-style {
  //background-color: #f3f6f8;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 2%;
  margin-bottom: 2%;

  @media screen and (max-width: 540px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    margin-top: 0.83rem;
    margin-bottom: 0.83rem;
  }
  li {
    display: table;
    counter-increment: item;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  li:before {
    content: counters(item, ".") ".0 ";
    display: table-cell;
    padding-right: 0.6em;
    font-weight: 700;
  }
  li li {
    margin: 0;
    margin-bottom: 0.6em;
  }
  li li:before {
    content: counters(item, ".") " ";
  }

  .Poppins {
    font-family: "Poppins";
    font-size: 0.875rem !important;
    font-weight: 400;
    letter-spacing: unset !important;
    text-transform: unset !important;
    line-height: 1.5;
    color: #061b01;
  }
  .Poppins-Bold {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 0.875rem !important;
  }
  .color-blue {
    color: blue;
  }

  .heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1rem !important;
    color: #061b01;
  }
  .topic {
    /* color: #000; */
    /* font-family: 'Poppins'; */
    font-weight: 700;
    font-size: 1.25rem !important;
    text-align: center;
    color: #061b01;
    font-family: "Gilroy-Regular";
    font-weight: 700;
  }

  .custom-table {
    display: table;
  }

  .custom-table-row {
    display: table-row;
  }

  .custom-table-cell {
    display: table-cell;
    vertical-align: middle;
  }

  .custom-bullet {
    display: inline-block;
    margin-right: 0.6rem;
    color: black; /* Customize the bullet color */
    font-weight: 500;
  }
}
