//
// Text
//

// Text colors
@each $name,
$color in $theme-text-colors {
  // Base color


  .section-title .section-title-header {
    display: grid;
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;


    color: #061B01;
  }

  .section-title-body {
    font-family: 'Poppins' sans-serif;
    ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #666666;
  }

  .service {
    background-color: var(--clr-white);
    padding: 3rem 1.5rem;
    margin-bottom: 2rem;
    border-radius: var(--radius);
    text-align: center;
    transition: var(--transition);
    box-shadow: 0 4px 8px 0 rgba(243, 243, 243, 0.988), 0 6px 20px 0 rgba(243, 243, 243, 0.988);


  }

  .service-icon {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .service .underline {
    width: 3rem;
    height: 0.12rem;
    transition: var(--transition);
  }

  .service p {
    transition: var(--transition);
  }


  .service:hover {
    // background: var(--clr-primary-5);
    // color: var(--clr-white);
  }

  .service:hover p {
    // color: var(--clr-white);
  }



  .service:hover .underline {
    // background: var(--clr-white);
  }

  .service-content-header {
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    display: grid;
    margin-bottom: 2rem;
    margin-top: 2rem;


    color: #061B01
  }

  .service-content-body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    text-justify: inter-word;
    color: #666666;
  }

  @media screen and (min-width: 676px) {
    .services-center {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
    }
  }

  @media screen and (min-width: 992px) {
    .services-center {
      grid-template-columns: 1fr 1fr 1rfr;
    }
  }

  .bg-grey {
    background: #F9F9F9;
  }

}