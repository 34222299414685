//
// Text
//

// Text colors

// Base color

.root {
  display: grid;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 4rem;
}

.signup-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.signup-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.email-verification-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  letter-spacing: -1px;

  color: #08140c;
}

.email-verification-container {
  display: grid;
  padding: 0.5rem;
}

.email-verification-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 126.5%;
  /* or 15px */

  text-align: center;
  letter-spacing: 0.2px;

  color: #666666;
}

.line {
  display: flex;
  height: 4px;
  width: 31%;
  // position: absolute;
  background-color: #1f8505;
  transform: translateY(-50%);
}

.side-1 {
  display: grid;
  justify-content: start;
}

.item-active {
  background: #1f8505;
  border: 4px solid #fbcb38;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  place-content: center;
  place-items: center;
  border-radius: 90px;
  color: #fff;
}

.item-inactive {
  background: #d9d9d9;
  border: 4px solid #ffffff;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  place-content: center;
  place-items: center;
  border-radius: 90px;
  color: #fff;
}

.item {
  display: flex;
  margin-right: 20px;
}

.side-2 {
  display: grid;
  justify-content: end;
}

.signup-remeber-text {
color: #08140C;
font-family: 'Poppins';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;

a{
  color: var(--Secondary-Color, #FBCB38);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
}

.form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  margin-bottom: 2rem;

  color: #08140c;
}

.login-rem-grid-signup p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #08140c;
}

.signup-remeber-text-step2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #666666;
}

.login_text_signup {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #08140c;
}

.google-auth-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;

  color: #666666;
}

.reg-play-store-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #40a060;
  cursor: pointer;
}

.corporate-container-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;

  @media screen and (max-width: 768px) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
  }
}

@media screen and (min-width: 768px) {
  .corporate-container-form {
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
}

.corporate-container-form-item-1 {
  display: grid;
  justify-content: start;
}

.corporate-container-form-item-2 {
  display: grid;
  justify-content: end;
}

.otp-box {
  display: flex;
}

.otp-box input {
  width: 51px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 2px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  color: #08140c;
  outline: none;
  transition: all 0.1s;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%;
  /* or 20px */

  letter-spacing: 0.2px;
}

.otp-box input:focus {
  border: 2px solid #27ffa9;
  box-shadow: 0 0 2px #27ffd76a;
}

.otp-button {
  width: 104px;
  height: 41px;

  background: #1f8505;
  border: transparent;
  border-radius: 4px;
}

.otp-button-addmore {
  width: 104px;
  height: 41px;

  border: 1px solid #1f8505;
  color: #1f8505;
  border: transparent;
  border-radius: 4px;
}

.otp-button-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  letter-spacing: -1px;

  color: #ffffff;
}

.otp-button-addmore {
  // border-color:  #1F8505;
  border: 1px solid #1f8505;
  color: #1f8505;
  cursor: pointer;
  // justify-content: space-between
  justify-self: end;
  background: transparent;
  border-radius: 4px;
  padding: 6px 17px;
  gap: 10px;
  width: 126px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
}

.resend-code-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 126.5%;
  /* or 15px */

  text-align: center;
  letter-spacing: 0.2px;
  text-decoration-line: underline;

  color: #1f8505;
  cursor: pointer;
}

.twitter-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  color: #0da4c6;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 145px;

  background: #edf9f1;
  border: 2px dashed #61cd85;
  border-radius: 4px;
  cursor: pointer;
}

.form-text-kyc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #08140c;
}

.kyc-bottom-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #999999;
}

.kyc-inside-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #08140c;
}

.kyc-upload-items-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #08140c;
}

.kyc-uploaded-items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 0.5rem;
}

.kyc-uploaded-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #40a060;
}

.add-more-account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #40a060;
}

.add-more-account-root {
  cursor: pointer;
}

.add-more-account-root-cancle {
  cursor: not-allowed;
}

.bank-account-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.account-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #08140c;
}

.side-1-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #08140c;
}

.side-1-text-active::after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #fbcb38;
  margin: 0.3rem auto;
  display: block;
}

.side-1-text-active::after {
  width: 100%;
  transition: width 0.3s linear;
}

.user-creation-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #08140c;
}

.user-creation-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #666666;
}

.user-modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #08140c;
}

.user-save-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-save-cont-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #40a060;
}

.select-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-option-side {
  border: 1px solid #40a060;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

table {
  background-color: #F9F9F9;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

// @media (max-width: 600px) {
//   /* Change table layout for small screens */
//   table, thead, tbody, th, td, tr {
//     display: block;
//   }

//   /* Hide table headers */
//   thead tr {
//     position: absolute;
//     top: -9999px;
//     left: -9999px;
//   }

//   td {
//     /* Add a border bottom to each table data */
//     border: none;
//     border-bottom: 1px solid #eee;
//     position: relative;
//     padding-left: 50%;
//   }

//   td:before {
//     /* Add table header as pseudo-element */
//     content: attr(data-header);
//     position: absolute;
//     left: 0;
//     width: 45%;
//     padding-right: 10px;
//     font-size: 14px;
//     font-weight: bold;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// }

.table {
  border-collapse: collapse;
  width: 100%;
  font-family: $font-family-sans-serif;
}

.table th,
.table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #40a060;
  color: #fff;
}

.table tbody tr {
  color: #000000;
  // background-color: #f2f2f2;
}

.user-btn {
  // background: transparent;
  // border-color: transparent;
}

.step-text-link {
  color: #fbcb38;
  cursor: pointer;
}

.approval-summary-workflow {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.8);
}

.user-work-flow-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}

.user-work-flow-content {
  display: grid;
  justify-content: center;
  align-items: center;
}

.user-work-flow-item-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(8, 20, 12, 0.5);
}

.user-work-flow-item-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #08140c;
}
