//
// Text
//

// Text colors
@each $name,
$color in $theme-text-colors {


  .root {
    width: 100vw;
    height: 100%;
    display: grid;
  }

  .help-center-hero {
    width: 100vw;
    display: grid;
    justify-self: center;
    padding: 2rem;
    

  }

  .help-center-img-card {
    width: 100%;
    height: 40vh;
    display: grid;
    background: url("../../../imgs/src/services-one-shape\ 25x.png") center center no-repeat, linear-gradient(99.13deg, #1F8505 43.08%, #F1B807 137.39%);
    background-size: cover;
    border-radius: 5px;
  }


  .help-center-item {
    color: white;
    display: grid;
    justify-content: flex-start;
    place-items: center;
    margin-left: 5%;
  }

  .help-center-faq1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    display: grid;
    color: #F9F9F9;
  }

  .help-center-faq2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height */
    display: grid;


    color: #FBCB38;
  }

  .help-center-faq3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #CCCCCC;
  }

  .help-center-section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem
  }

  .help-center-small-text {
    display: grid;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */


    color: #1F8505;
  }

  .help-center-bold-text {
    display: grid;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */


    color: #061B01;
  }

  .help-center-normal-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: normal;
    color: #666666;
    align-items: center;
  }



  .help-center-form {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 603px;
    background: #f9f9f9;
    padding: 2rem;
  }

  .help-center-root {
    display: grid;
    background: #f9f9f9;
    // clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 86%);
  }

  .help-center-root-form {
    display: grid;
    justify-content: center;
    padding: 2rem;
    background: linear-gradient(0deg, #d2e7cd, #d2e7cd), #979797;
  }

  .help-form {
    // width: 200px;
    // height: 48px;
    // background: #F9F9F9;;
    // padding: 1rem;
    // border-color: 0.1px solid #ccc;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    height: 48px;
    padding: 4px;
    padding: 4px;

    background: #f9f9f9;
    border-color: 0.5px solid #fff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .help-center-root-faq {
    display: grid; 
    justify-content: start;
    padding: 2rem;
    background: #f9f9f9;
    margin-bottom: 6rem;
  }

  .faq-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #d2e7cd;
    border-radius: 7px 7px 0px 0px;
    width: 98%;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    white-space: nowrap;
  }

  .help-contact {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    cursor: pointer;
    text-decoration-line: underline;

    color: #1F8505;
  }

  .faq-header .faq-header-text {
    display: grid;
    align-items: center;

    justify-content: start;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #1f8505;
  }

  .faq-header span {
    display: grid;
    align-items: center;
    justify-content: end;
  }

  .faq-body {
    display: grid;
    height: auto;
    border: 1px solid #f3dfdf;
    background: #f9f9f9;
    padding: 1rem;
    width: 98%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    color: rgba(19, 18, 18, 0.8);
  }

  .help-center-header-text {
    display: grid;
    font-family: "Poppins" sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #061b01;
    margin-bottom: 27px;
  }

  .help-center-header-body {
    font-family: "Poppins" sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 43px;
    display: grid;
    color: #666666;
  }

  .help-email {
    font-family: "Poppins" sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: block;
    color: #1f8505;
  }

  .faq_frequently-asked-header {
    font-family: "Poppins" sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */
    display: grid;
    margin-top: 149px;

    color: #061b01;
  }

  .faq_frequently-asked-body {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: grid;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #666666;
  }

  .hero-photo-video {
    margin-top: 1.25rem;
  }

  .footer {
    padding-bottom: 2rem;

    background: url("../../../imgs/src/services-one-shape\ 25x.png") center center no-repeat, #061B01 !important;

    display: grid;
    grid-template-columns: 1fr;
    // place-items: center;
    padding: 2.813rem;
    gap: 2rem;
    color: #fff;

    @media screen and (min-width: 992px) {
      gap: 4rem;

      .social-icons-footer {
  
        display: grid;
        // margin-top: -4rem;
        width: 20rem;
        grid-template-columns: repeat(5, 1fr);
        // justify-items: center;
      }
    }

  }

  .footer-center {
    display: block;
    place-items: center;
    margin-left: 2rem;
  }

  .social-icons-footer {
  
    display: grid;
    // margin-top: -4rem;
    width: 20rem;
    grid-template-columns: repeat(5, 1fr);
    // justify-items: center;
  }

  .social-icon-footer {
    font-size: 1rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }

  .footer .social-icons {
    margin-bottom: 2rem;
  }

  .footer .social-icon {
    color: var(--clr-primary-10);
  }

  .footer p {
    font-size: 1.25rem;
    text-transform: capitalize;
  }

  .footer-quick-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150.5%;
    /* identical to box height, or 27px */
    
    
    color: #1F8505;
  }

  .footer-quick-link-text {
    display: grid;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 118%;
    /* or 17px */
    
    
    color: #FFFFFF;
  }

  .footer-quick-link-icon {
    display: grid;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    margin-top: 1rem;
    white-space: nowrap;
    color: #1f8505;
    font-size: 1rem;
  }

  .footer-quick-link-icon span {
    color: #666666;
    justify-content: start;
  }

  .footer-bottom {
    display: grid;
    place-content: end;
    place-items: end;
    // background: url("../../../imgs/src/services-one-shape\ 25x.png") center center no-repeat, #061B01 !important;
    padding-bottom: 2rem;
    border-top: 2px dashed rgba(213, 213, 213, 0.2);
    padding: 2rem;
  }

  .footer-bottom-div {
    // display: grid;
    margin-top: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff !important;
    padding: 2rem;

  }

  .social-icons{
    display: grid;
    margin-top: 3rem;
    width: 20rem;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
  }

  .footer--center-bottom {
    display: grid;
    grid-template-columns: 1fr;
    background: #FEF5D7 !important;
    background: #FEF5D7;
    border-radius: 5px;
  }

  .foot-news-letter {
    // justify-content: center;
    padding: 1rem;
    width: 100%;
    // display: flex;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      // justify-content: flex-end;
    }
  }

  .news-letter-container {


    display: flex;
    background: #FEF5D7;
    padding: 1rem;
    justify-items: center;
    flex-direction: column;

    @media screen and (max-width: 780px) {
      width: 100%;
    }

    .span-header {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */
      color: #061B01;
    }
  }

  .right-side {
    // padding: 1.5rem;
    display: none;
  }
  .subscribe-policy-body{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
/* identical to box height */
margin-top: 0.5rem;

// display: none;
color: #061B01;
  }


     .subscribe-policy-body-link{
      color: #1F8505;
      cursor: pointer;

    }

  @media screen and (min-width: 992px) {
    .news-letter-container button {
      display: grid;
      margin-left: 0.75rem;
      //  margin-top: 2rem;
    }

    .help-center-section {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

    }


    .help-center-hero {
      width: 90vw;
      display: grid;
      justify-self: center;
      padding: 3rem;

    }

    .foot-news-letter {
      display: grid;
      // justify-content: end;
      grid-template-columns: 1fr 1fr;
      margin-right: 7rem;
      padding: 1rem;
    }

    .subcriber-pool {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

display: grid;
      color: #061B01;
    }

    .subcriber-pool-body {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      color: #666666;
    }

    .right-side {
      padding: 1.5rem;
      display: grid;
    }

    .subscribe-policy-body{
      // display: grid;
      font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
/* identical to box height */
margin-top: 0.5rem;
white-space: nowrap;


color: #061B01;
    }

 

    // .news-letter-container button {
    //   display: grid;
    //   margin-left: 3rem;
    // }

    .news-letter-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .help-center-root {
      display: grid;
      background: #f9f9f9;
      clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 86%);
    }

    .help-center-root {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .faq-body {
      display: grid;
      height: auto;
      border: 1px solid #f3dfdf;
      background: #f9f9f9;
      padding: 1rem;
      width: 90%;
    }

    .faq-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #d2e7cd;
      border-radius: 7px 7px 0px 0px;
      width: 90%;
      padding: 1rem;
    }

    .help-center-root-form {
      display: grid;
      height: 676px;
      justify-content: center;
      padding: 2rem;
      background: linear-gradient(0deg, #d2e7cd, #d2e7cd), #979797;
    }

    .help-form {
      width: 400px;
      height: 48px;
      background: #f9f9f9;
      border-color: 1px solid #ccc;
    }

    .hero-photo-video {
      margin-top: 6.25rem;
    }

    .footer-container{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      
    }

    .footer {
      padding-bottom: 2rem;
      background: url("../../../imgs/src/services-one-shape\ 25x.png") center center no-repeat, #061B01 !important;
    
      // place-items: center;
      padding: 4rem;
    }
  }

  // @media screen and (min-width: 992px) {
  //   .right-side {
  //     display: grid;
  //     padding: 1.5rem;
  //     // display: none;
  //   }
  // }

  .footer-news-body {
    width: 631px;
    display: grid;

    @media screen and (max-width: 780px) {

   
  
      width: 100%;
    }
  }

  .flex-small {
    display: flex !important;
    flex-direction: row !important;

    @media screen and (max-width: 780px) {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .flex-col {
    flex: 0 0 auto;
    width: 66.66666667%;

    @media screen and (max-width: 560px) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  .flex-subscribe {
    flex: 0 0 auto;
    width: 25%;
    margin-left: 0.75rem !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 780px) {
      flex: 1 0;
      width: 100%;
      margin-top: 1rem !important;
    }
  }
}