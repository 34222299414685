.mdaDetailsPage {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;


  .mdaDetailsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding-block: 25px 12px;
    border-bottom: 1px solid #CCCCCC;
  }

  .mdaDetailsRow1 {
    margin-block: 70px 28px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 73px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #08140C;
      padding-bottom: 14px;
      padding-inline: 80px;
    }

    > :nth-child(2) {
      border-top: 1px solid #E8E8E8;
      padding-top: 36px;
      padding-inline: 80px;

      > div {
        display: flex;
        justify-content: space-between;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }

        > :last-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }
      }
    }
  }

  .mdaDetailsRow2 {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 73px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #08140C;
      padding-bottom: 14px;
      padding-inline: 80px;
    }

    > :nth-child(2) {
      border-top: 1px solid #E8E8E8;
      padding-top: 36px;
      padding-inline: 80px;

      > div {
        display: flex;
        justify-content: space-between;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }

        > :last-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }
      }
    }
  }

  .mdaDetailsRow3 {
    margin-block: 40px 28px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 73px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #08140C;
      padding-bottom: 14px;
      padding-inline: 80px;
    }

    > :nth-child(2) {
      border-top: 1px solid #E8E8E8;
      padding-top: 36px;
      padding-inline: 80px;

      > div {
        display: flex;
        justify-content: space-between;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }

        > :last-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 35px;
          color: #08140C;
        }
      }
    }
  }

  .mdaDetailsRow4 {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 73px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #08140C;
      padding-bottom: 14px;
      padding-inline: 80px;
      border-bottom: 1px solid #E8E8E8;
    }

    .processTypeTables {
      > :first-child {
        padding-block: 36px 20px;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }
      }
    }
  }

  .mdaDetailsRow5 {
    display: flex;
    justify-content: right;
    gap: 18px;
    padding-top: 28px;

    > :first-child {
      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        // background: #EE151D;
        border-radius: 4px;
        padding: 12px 47px;
        // border: none;
      }
    }

    > :last-child {
      button {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        // background: #1F8505;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 11px 44px;
      }
    }
  }
}