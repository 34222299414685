@use 'core/assets/sass/components/variables' as *;

.PaymentReportContainer {
    //width: 250px !important;
    min-height: 338px;
    margin: 100px auto auto auto;
    background-color: $white;

    @media screen and (min-width: 991px) {
        width: 864px !important;
    }

    padding: 50px 40px;

    .input-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.25rem;

        @media screen and (max-width: 719px) {
            grid-template-columns: 1fr;
            gap: 0.635rem;
        }
    }


    .ant-picker {
        background-color: #f9f9f9;
        border: 1px solid #E5E5E5;
        color: #061b01;
        border-radius: 0.25rem;
        border: 0;
        height: 2.313rem;
        display: flex;

        .ant-picker-input>input {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: start !important;
            color: #000000;
        }
    }

    .resetBtn {
        border-radius: 4px;
        border: 1px solid $color-success;
        color: $color-success;
        background: $white;
        display: flex;
        width: 185px !important;
        height: 40px;
        padding: 14px 7px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0
    }

    .submitBtn {
        border-radius: 4px;
        border: 1px solid $color-success;
        color: $white;
        background: $color-success;
        display: flex;
        width: 185px !important;
        height: 40px;
        padding: 14px 7px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        &:hover {
            border: 1px solid $color-success !important;
            color: $white !important;
            background: $color-success !important;
        }
    }

    .ant-radio-wrapper{
        .ant-form input{
            border: 1px solid $color-success !important;
            background-color: $color-success;
        }
        
        .ant-radio-checked .ant-radio-inner {
            border: 1px solid $color-success !important;
            background-color: $color-success;
    
            &:hover {
                border: 1px solid $color-success !important;
                color: $white !important;
                background: $color-success !important;
            }
        }
    }
   


    .ant-select-selector {
        border: 1px solid $success-box !important;
    }

    .ant-picker-outlined {
        border: 1px solid $success-box  !important;
    }

    .ant-select.ant-select-in-form-item {
        height: 2.313rem;

    }

    .ant-input {
        background-color: #f9f9f9;
        border: 1px solid #E5E5E5;
        color: #061b01;
        border-radius: 0.25rem;
        border: 0;
        height: 2.313rem;
        display: flex;
        border: 1px solid $success-box  !important;
        padding: 4px 11px 4px;  
        &:focus {
            border: 1px solid $success-box  !important;   
        }
    }

}