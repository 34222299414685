.revenueValidation {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .revenueValidationRow1 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding-block: 30px 12px;
    margin-bottom: 30px;

    .revenueValidationBreadcrumb {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }

      > :last-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #08140C;
      }
    }

    > :last-child {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .revenueValidationRow2 {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 50px 30px;

    > :first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

     > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 18px;
        color: #08140C;
      }

      > :nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
        }
      }
    }

    > :nth-child(2) {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      gap: 20px;
      padding-block: 25px 35px;

      > :nth-child(2) {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #1F8505;
          background: #D2E7CD;
        }
      }

      > :last-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #FBCB38;
          background: #FEF5D7;
        }
      }
    }
  }

  .revenueValidationActionButton {
    //display: flex;
    //flex-direction: row;
    //align-items: flex-start;
    padding: 3px 10px;
    //gap: 10px;
    width: 105px;
    height: 27px;
    //background: #CCCCCC;
    background: #1F8505;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    border: none;
  }
}
