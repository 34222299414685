@use 'core/assets/sass/components/variables' as *;



.confirmationModal {
    display: grid;
    gap: 40px;
    padding-inline: 30px;
    justify-content: center;
    margin: 40px 0;
    text-align: center;

    .confirmationModalTitle {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #08140C;
        text-align: center;
    }

    .confirmationModalActionButton {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}