//
// Text
//

// Text colors
@each $name, $color in $theme-text-colors {
  // Base color

  .hero .underline {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  .hero-img {
    display: none;
  }

  .hero-img-2 {
    display: none;
  }

  .balance-card-root {
    display: none;
  }

  .hero {
    background: #fff;
    margin-top: 4rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 885px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .hero-center {
    // min-height: calc(100vh - 20rem);
    display: grid;
    place-items: center;
    text-align: center;
  }

  .hero-info span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    display: block;
    margin-bottom: 6;

    /* identical to box height */

    color: #c9a22d;
  }

  .hero-info .hero-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #666666;
  }

  .hero-icons {
    justify-items: start;
  }

  .hero-btn {
    margin-top: 1.25rem;
  }

  .quick-links {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    justify-content: center;
    justify-items: center;
  }

  .quick-links span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    /* identical to box height, or 267% */

    white-space: nowrap;

    color: #666666;
  }

  .img-div {
    align-items: center;
    padding: 20px 30px;
    gap: 5px;
    width: 228px;
    height: 136px;
    background: #fef5d7;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: var(--light-shadow);
    cursor: pointer;
  }

  .img-div:hover {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #f4db89;
  }

  .img-items {
    margin: 0 auto;
  }

  .quick-links div img {
    max-width: 3.6875rem;
    max-height: 3.6875rem;
    object-fit: cover;
  }

  .getStarted-root {
    width: 100% !important;
    text-align: center;
    @media screen and (min-width: 992px) {
      width: 567px !important;
      text-align: left;
    }
  }

  .getStarted-root span {
    text-align: center !important;
    font-size: 1.5rem !important;
    line-height: 2.5rem !important;
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;

    @media screen and (min-width: 992px) {
      text-align: left !important;
      font-size: 1.8rem !important;
      line-height: 2.813rem !important;
    }
  }

  .d-flex-alt {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 580px) {
      display: flex;
      flex-direction: column;
    }
  }

  .btn-getstarted {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  .btn-getstarted button {
    justify-content: center;
    align-items: center;
    padding: 12px 34px;
    gap: 10px;
    width: 206px;
    height: 51px;
    background: #1f8505;
    border-radius: 4px;
    border-color: transparent;
  }

  .btn-getstarted .btn-getstarted-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff !important;
  }

  .getStarted-text span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #666666;
  }

  @media screen and (min-width: 580px) {
    .quick-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      justify-content: center;
      justify-items: center;
    }
  }

  @media screen and (min-width: 992px) {
    // .hero-img-root {
    //   // margin-top: 20px;
    // }

    .hero-img {
      display: block;
      position: relative;
      // top: calc(100vh * 1.2);
      // left: calc(100vh * 0.9);
      // width: calc(100vh * 0.5);
      // height: calc(100vh * 0.6);
    }

    // .hero-img-2 {
    //   display: block;
    //   position: absolute;
    //   top: calc(100vh * 1);
    //   left: calc(100vh * 0.6);
    //   width: calc(100vh * 0.52);
    //   height: calc(100vh * 0.51);
    // }

    // .balance-card-root {
    //   display: block;
    //   position: absolute;
    //   top: calc(100vh * 1);
    //   left: calc(100vh * 0.6);
    //   width: calc(100vh * 0.52);
    //   height: calc(100vh * 0.51);
    // }

    // .balance-card {
    //   position: absolute;
    //   width: 367px;
    //   height: 102px;
    //   left: 155px;
    //   top: 73px;
    //   background: #FFFFFF;
    //   box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    //   border-radius: 5px;
    // }

    // .balance-card-content {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    // }

    // .balance-card-icon {
    //   justify-self: start;
    //   padding: 2.7rem;

    // }

    // .balance-card-number {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 20px;
    //   line-height: 30px;
    //   /* identical to box height */

    //   color: #061B01;
    // }

    // .balance-card-current {
    //   justify-self: end;
    //   padding: 1rem;

    // }

    // .balance-card-hide {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 18px;
    //   color: #666666;
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;

    // }

    // .balance {
    //   font-family: 'Poppins' sans-serif;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 18px;
    //   /* identical to box height */
    //   color: #666666;
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    //   white-space: nowrap;

    // }

    .hero-center {
      grid-template-columns: 1fr;
    }

    .hero-photo {
      width: 34.313rem !important;
      height: 23.375rem !important;
      object-fit: cover;
      border-radius: var(--radius);
      position: relative;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    // .hero-img::before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border: 0.25rem solid var(--clr-primary-5);
    //   top: 2rem;
    //   right: -2rem;
    //   border-radius: var(--radius);
    // }
    .about-img {
      width: 34.75rem;
      height: 34.125rem;
      object-fit: cover;
      border-radius: var(--radius);
      position: relative;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .quick-links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2rem;
      justify-content: center;
      justify-items: center;
    }
  }

  // .hero-photo {
  //   max-width: 35.3125rem;
  //   max-height: 33.6875rem;

  //   object-fit: cover;
  //   border-radius: var(--radius);
  //   position: relative;
  // }
}

.arrow-style {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.welcome-body-root {
  width: 567px !important;
  text-align: left;
  margin: 2rem;

  @media screen and (max-width: 580px) {
    width: 100% !important;
    margin: 0.5rem;
  }

  }
