.electronicInvoicePayment {
  padding: 40px 30px;
  overflow-y: auto;


  .eipBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  .eiPaymentDiv {
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    width: 479px;
    padding: 30px 40px;
    margin: auto;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #061B01;
      text-align: center;
    }

    > :nth-child(2) {
      padding-block: 29px 42px;

      label {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :last-child {
      display: flex;
      gap: 40px;
      justify-content: center;

      > :first-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #EE151D;
          background: #FDE7E8;
          border-radius: 4px;
          padding: 12px 26px;
          border: none;
        }
      }

      > :nth-child(2) {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          background: #1F8505;
          border-radius: 4px;
          padding: 12px 37px;
          border: none;
        }
      }
    }
  }

  .billPaymentDetailDiv {
    .billPaymentDetail {
      background: #FFFFFF;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      width: 480px;
      margin: 60px auto 32px auto;
      align-self: center;
      padding: 24px 40px;

      > :first-child {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #061B01;
        padding-bottom: 17px;
        text-align: center;
      }

      > :last-child {
        padding-block: 21px 19px;
        border-top: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;

        >div {
          display: flex;
          justify-content: space-between;

          > :first-child {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 32px;
            color: #666666;
          }

          > :nth-child(2) {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 32px;
            color: #061B01;
          }
        }
      }
    }
  }

  .billPaymentFundingAcct {
    background: #FFFFFF;
    border-radius: 5px;
    width: 480px;
    padding: 30px 40px;
    margin: auto;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #061B01;
    }

    > :nth-child(2) {
      padding-block: 13px 59px;
    }

    > :last-child {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #CCCCCC;
      padding-block: 16px 40px;

      > :first-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #1F8505;
          border: 1px solid #1F8505;
          padding: 12px 40px;
          background: #FFFFFF;
          border-radius: 4px;
        }
      }

      > :nth-child(2) {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          border: none;
          padding: 13px 46px;
          background: #1F8505;
          border-radius: 4px;
        }
      }
    }
  }

  .billPaymentReceived {
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    width: 480px;
    padding: 62px 40px;
    margin: auto;

    > :first-child {
      text-align: center;
    }

    > :nth-child(2) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #061B01;
      padding-top: 24px;
      text-align: center;
    }

    > :nth-child(3) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      color: #666666;
      padding-block: 5px 15px;
      text-align: center;
    }

    > :nth-child(4) {
      padding-block: 21px 19px;
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;

      >div {
        display: flex;
        justify-content: space-between;

        > :first-child {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 32px;
          color: #666666;
        }

        > :nth-child(2) {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 32px;
          color: #061B01;
        }
      }
    }

    > :last-child {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #CCCCCC;
      padding-block: 38px 28px;

      > :first-child {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #1F8505;
          border: 1px solid #1F8505;
          padding: 12px 34px;
          background: #FFFFFF;
          border-radius: 4px;
        }
      }

      > :nth-child(2) {
        button {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          border: none;
          padding: 13px 34px;
          background: #1F8505;
          border-radius: 4px;
        }
      }
    }
  }
}

.makeABillPayments {
  padding: 30px;
  //padding: 40px 30px;
  overflow-y: auto;

  .mabPaymentsBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    padding-block: 23px 60px;

    > :last-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #061B01;
    }
  }

  > :last-child {
    margin: auto;
    background: #FFFFFF;
    padding: 40px;
    width: 480px;

    > :first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #061B01;
    }

    > :nth-child(2) {
      padding-block: 40px;

      label {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    > :nth-child(3) {
      text-align: center;
      padding-bottom: 30px;

      button {
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #1F8505;
        padding: 12px 34px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #1F8505;
      }
    }

    > :last-child {
      text-align: center;

      button {
        background: #1F8505;
        border-radius: 4px;
        border: none;
        padding: 12px 34px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}

