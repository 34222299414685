//
// Form Check
//

.form-check {
  // Label
  .form-check-label {
    cursor: pointer;
  }

  // Input
  .form-check-input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  // Custom
  &.form-check-custom {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;

    .form-check-input {
      margin: 0;
      float: none;
      flex-shrink: 0;
    }

    .form-check-label {
      margin-left: 0.55rem;
    }
  }

  &:not(.form-switch) {
    .form-check-input {
      &[type="checkbox"] {
        background-size: $form-check-input-bg-size;
      }
    }
  }

  // Solid
  &.form-check-solid {
    .form-check-input {
      border: 0;
      background-color: $white;
      border: 1px solid $gray-500 !important;
      background-size: 81% !important;
      background-image: escape-svg($form-switch-unchecked-bg-image);

      &:active,
      &:focus {
        filter: none;
        background-color: $form-check-input-bg-solid;
      }

      &:checked {
        background-color: $form-check-input-checked-bg-color;
        background-image: escape-svg($form-switch-checked-bg-image) !important;
      }
    }
  }

  // alt
  &.form-check-alt {
    .form-check-input {
      border: 0;
      background-color: $white;
      border: 1px solid $primary-500 !important;
      background-size: 81% !important;
      background-image: escape-svg($form-switch-unchecked-bg-image);

      &:active,
      &:focus {
        filter: none;
        background-color: $form-check-input-bg-solid;
      }

      &:checked {
        background-color: $form-check-input-checked-bg-color;
        border: 1px solid $primary-500 !important;
        background-image: escape-svg($form-switch-checked-bg-image) !important;
      }
    }
  }

  // Success state
  &.form-check-success {
    .form-check-input {
      &:checked {
        background-color: $success;
      }
    }
  }

  // Danger state
  &.form-check-danger {
    .form-check-input {
      &:checked {
        background-color: $danger;
      }
    }
  }

  // Warning state
  &.form-check-warning {
    .form-check-input {
      &:checked {
        background-color: $warning;
      }
    }
  }
}

// Sizing
.form-check.form-check-custom {
  &.form-check-sm {
    .form-check-input {
      height: $form-check-input-width-sm;
      width: $form-check-input-width-sm;
    }
  }

  &.form-check-lg {
    .form-check-input {
      height: $form-check-input-width-lg;
      width: $form-check-input-width-lg;
    }
  }
}

// Form switch
.form-switch.form-check-solid {
  .form-check-input {
    height: $form-switch-height;
    background-image: escape-svg($form-switch-bg-image-solid);
    border-radius: $form-switch-border-radius;

    &:checked {
      filter: none;
      background-image: escape-svg($form-switch-checked-bg-image);
    }
  }

  &.form-switch-sm {
    .form-check-input {
      height: $form-switch-height-sm;
      width: $form-switch-width-sm;
    }
  }

  &.form-switch-lg {
    .form-check-input {
      height: $form-switch-height-lg;
      width: $form-switch-width-lg;
    }
  }
}

.form-check-input-alt:checked[type="checkbox"] {
  background-color: $color-success !important;
  background-image: url(./check.svg) !important;
}


.ant-table-container table>thead>tr:first-child >*:last-child {
  border-radius: 0!important;
  border-start-end-radius:  0!important;
}

.ant-table-container table>thead>tr:first-child >*:first-child {
  border-radius: 0!important;
  border-start-start-radius:  0!important;
}