@use 'core/assets/sass/components/variables' as *;


.breadcrumb-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #050649;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 20px 0 30px 0;
    margin-left: 5px;
    line-height: 25px;
}