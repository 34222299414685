.userManagement {
  background: #F9F9F9;
  padding-inline: 45px;
  padding-bottom: 70px;
  height: 100%;
  overflow-y: auto;

  .userManagementBreadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 30px 48px;

    > :first-child {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > :last-child {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .userManagementRow1 {
    display: flex;
    gap: 30px;
    margin-bottom: 48px;

    > div {
      padding-block: 24px 40px;
      padding-inline: 20px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
      width: 348px;
      height: 138px;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid var(--primary-color, #1F8505);
      }

      > :first-child {
        color: #061B01;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 15px;
      }

      > :nth-child(2) {
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .userManagementTab {
    background: #FFFFFF;
    padding-inline: 20px;
    padding-block: 16px 32px;
    border-radius: 5px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

    .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      color: #061B01 !important;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: #1F8505 !important;
    }
  }

  .userManagementRow2 {
    > :nth-child(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > :nth-child(2) {
        display: flex;
        gap: 3px;
        justify-content: right;

        > :first-child {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        > :nth-child(2) {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }

}

.editUser {
  .editUserMainView {
    > :first-child {
      width: 550px;
      margin: 40px auto 30px auto;
    }

    > :nth-child(3) {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      padding-bottom: 20px;
    }
  }

  .editUserEditView {
    padding: 20px 40px;

    > :first-child {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-bottom: 20px;

      > :last-child {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > :nth-child(2) {
      border-radius: 5px;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08), -4px -4px 8px 0px rgba(0, 0, 0, 0.08);

      .userDetailsText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E8E8E8;
        padding-block: 30px 14px;
        padding-inline: 86px;

        > :first-child {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > :nth-child(2) {
          button {
            border-radius: 4px;
            background: #0DA4C6;
            color: #FFFFFF;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            padding: 7px 14px;
            display: flex;
            gap: 9px;
            align-items: center;
          }
        }
      }

      .userDetailsDiv {
        padding-inline: 86px;
        padding-block: 20px 32px;
        border-bottom: 1px solid #E8E8E8;

        > div {
          display: flex;
          justify-content: space-between;
          padding-bottom: 18px;

          .title {
            color: #08140C;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .details {
            color: #08140C;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .userRoleResource {
        padding-inline: 86px;
        padding-block: 20px 32px;

        .userRole {
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration-line: underline;
          padding-bottom: 20px;
        }

        > :nth-child(2) {
          display: flex;
          justify-content: space-between;
          padding-bottom: 18px;

          .title {
            color: #08140C;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: 20px;
          }

          .details {
            color: #08140C;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 20px;
            padding-left: 27px;
          }
        }
      }
    }
  }
}

.createUser {
  padding-bottom: 40px;

  .userDetails {
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.08), -4px -4px 8px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 40px;
    width: 480px;
    margin: auto;

    .userDetailsTitle {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
    }

    .userDetailsDesc {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .userDetailsNextButton {
      display: flex;
      justify-content: space-around;

      button {
        padding: 12px 48px;
        border-radius: 5px;
        // background: #999999;
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
      }
    }
  }

  .workflowAssignment {
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.08), -4px -4px 8px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 40px;
    width: 480px;
    margin: auto;

    .workflowAssignmentTitle {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
    }

    .workflowAssignmentDesc {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 36px;
    }

    .workflowAssignmentCheckbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-bottom: 36px;

      > div {
        label {
          color: #08140C;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .checkbox-dropdown {
        display: grid;
        gap: 13px;
        padding-left: 30px;
        padding-bottom: 20px;

        label {
          display: flex;
          align-content: center;
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        input {
          margin-right: 15px;
        }
      }
    }

    .workflowAssignmentNextButton {
      display: flex;
      justify-content: space-around;

      button {
        padding: 12px 48px;
        border-radius: 5px;
        background: #1F8505;
        color: #FFFFFF;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
      }
    }
  }

  .userRoles {
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.08), -4px -4px 8px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 40px;
    width: 480px;
    margin: auto;

    .userRolesTitle {
      color: #08140C;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
    }

    .userRolesDesc {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 36px;
    }

    .userRolesRadioButton {
      display: grid;
      gap: 20px;
      padding-bottom: 36px;

      label {
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .checkbox-dropdown {
        display: grid;
        gap: 13px;
        padding-left: 30px;

        label {
          display: flex;
          align-content: center;
          color: #666666;
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        input {
          margin-right: 15px;
        }
      }
    }

    .userRolesNextButton {
      display: flex;
      justify-content: space-around;

      button {
        padding: 12px 48px;
        border-radius: 5px;
        background: #999999;
        color: #FFFFFF;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
      }
    }
  }
}



.svg-button {
  border: none;
  background: #FFFFFF;
}


.editUserDetailsModal {
  display: grid;
  gap: 10px;
  padding: 50px 40px;

  .editUserDetailsModalTitle {
    color: #08140C;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .editUserDetailsModalDesc {
    color: #666666;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-block: 10px 36px;
  }

  .editUserRolesRadioButton {
    display: grid;
    gap: 20px;
    padding-bottom: 36px;

    label {
      color: #666666;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .checkbox-dropdown {
      display: grid;
      gap: 13px;
      padding-left: 30px;

      label {
        display: flex;
        align-content: center;
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        margin-right: 15px;
      }
    }
  }

  .editUserDetailsCheckbox {
    display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-bottom: 36px;

    > div {
      label {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .checkbox-dropdown {
      display: grid;
      gap: 13px;
      padding-left: 30px;
      padding-bottom: 20px;

      label {
        display: flex;
        align-content: center;
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        margin-right: 15px;
      }
    }
  }

  .editUserDetailsModalActionButton {
    display: flex;
      justify-content: space-around;

      button {
        padding: 12px 48px;
        border-radius: 5px;
        background: #1F8505;
        color: #FFFFFF;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
      }
    }

}

.button-search{
     border-radius: 4px;
            background: #0DA4C6;
            color: #FFFFFF;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            padding: 7px 14px;
            // display: flex;
            gap: 9px;
            align-items: center;
}




  .editUserDetailsCheckbox {
    display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-bottom: 36px;

    > div {
      label {
        color: #08140C;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .checkbox-dropdown {
      display: grid;
      gap: 13px;
      padding-left: 30px;
      padding-bottom: 20px;

      label {
        display: flex;
        align-content: center;
        color: #666666;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        margin-right: 15px;
      }
    }
  }