@use 'core/assets/sass/components/variables' as *;

.project-content-container {
    background-color: $white;
    padding: 50px 47px;
    display: grid;
    gap: 30px;


    .header-container {
        display: flex;
        padding: 20px 20px 16px 20px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 0.5px solid $unchecked-switch;
        background: $white;
    }

:where(.css-dev-only-do-not-override-1drr2mu).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $gray-800;
    background: $gray-800;
}

}
.btn-clear {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
    border: 0 !important;
}

th.ant-table-cell {
    background-color: #d2e7cd !important;
    border-color: #d2e7cd !important;
}
